import { useCallback } from 'react';
import { FeatureFlagKey } from 'shared/enums/FeatureFlagKeys';
import { useCareAuthContext } from 'shared/providers/CareAuthProvider';
import useCareFeatureFlag from './useCareFeatureFlag';

const isProduction = process.env.REACT_APP_PRODUCTION === 'true';

enum HotjarAction {
  Identify = 'identify',
  StateChange = 'stateChange',
}

interface UserAttributes {
  [userAttribute: string]: string | number | Date | boolean;
}

type HotjarIdentify = (action: HotjarAction, userId: string, attributes?: UserAttributes) => void;

declare global {
  interface Window {
    hj: HotjarIdentify;
  }
}

const hotjarNotDefined = (): void => {
  console.error('Hotjar is not defined');
};

const useHotjar = (): {
  identify: (userAttributes?: UserAttributes) => void;
} => {
  const {
    clinicPetParent,
    clinicPetParentId,
    clinicPetParentEmail,
    clinic,
    isEnrolledInAtLeastOnePlan,
  } = useCareAuthContext();

  const { isFeatureEnabled } = useCareFeatureFlag();
  const clinicHasCareEnabled = isFeatureEnabled(FeatureFlagKey.CarePlans);

  const shouldHotjarRun = useCallback((): boolean => {
    if (!isProduction || !clinicPetParentId) return false;
    if (!window.hj) {
      hotjarNotDefined();
      return false;
    }
    return true;
  }, [clinicPetParentId]);

  const identify = useCallback(
    (userAttributes: UserAttributes = {}): void => {
      if (clinicPetParentId && clinic?.id) {
        try {
          if (!shouldHotjarRun()) return;
          const attributes: UserAttributes = {
            name: [clinicPetParent?.firstName || '', clinicPetParent?.lastName || ''].join(' ').trim(),
            email: clinicPetParentEmail || 'No email provided',
            clinicId: clinic.id,
            clinicName: clinic?.name || '',
            clinicHasCareEnabled,
            isEnrolledInAtLeastOnePlan,
            ...userAttributes,
          };
          window.hj(HotjarAction.Identify, clinicPetParentId, attributes);
        } catch (err) {
          console.log(err);
        }
      } else {
        hotjarNotDefined();
      }
    },
    [
      clinic?.id,
      clinic?.name,
      clinicHasCareEnabled,
      clinicPetParent?.firstName,
      clinicPetParent?.lastName,
      clinicPetParentEmail,
      clinicPetParentId,
      shouldHotjarRun,
      isEnrolledInAtLeastOnePlan,
    ],
  );

  return {
    identify,
  };
};

export default useHotjar;
