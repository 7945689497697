import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PathHistoryContext } from './contexts/PathHistoryContext';
import { IPathHistoryProvider } from './interfaces/IPathHistoryProvider';

interface PathHistoryProps {
  children: ReactNode;
}

export const usePathHistory = (): IPathHistoryProvider => useContext<IPathHistoryProvider>(PathHistoryContext);

const PathHistoryProvider = ({ children }: PathHistoryProps): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const [pathHistory, setPathHistory] = useState<string[]>([location.pathname]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if(action === 'PUSH')
        setPathHistory((currentPathHistory) => [...currentPathHistory, location.pathname]);
      else if(action === 'POP')
        setPathHistory((currentPathHistory) => {
          const newHistory = [...currentPathHistory];
          newHistory.pop();
          return newHistory;
        });
      else if(action === 'REPLACE')
        setPathHistory((currentPathHistory) => {
          const newHistory = [...currentPathHistory];
          newHistory.pop();
          newHistory.push(location.pathname);
          return newHistory;
        });
    });
    return unlisten;
  }, [history]);

  return (
    <PathHistoryContext.Provider value={{ pathHistory, lastPath: pathHistory[pathHistory.length - 2] }}>
      {children}
    </PathHistoryContext.Provider>
  );
};

export default PathHistoryProvider;
