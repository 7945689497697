import React, { useMemo } from 'react';
import ApolloProvider from 'shared/providers/ApolloProvider';
import { elementScrollIntoViewPolyfill } from 'seamless-scroll-polyfill';
import { coreApiUrl } from 'shared/utils/http';
import Routes from 'routes';
import UAParser from 'ua-parser-js';
import UnsupportedBrowser from './UnsupportedBrowser';
import CareAuthProvider from 'shared/providers/CareAuthProvider';
import { TokenProvider } from 'shared/providers/TokenProvider';
import { KibbleThemeProvider, theme } from '@televet/kibble-ui/build/theme';
import { commitInfo } from 'commitInfo';
import useLogRocket from 'shared/hooks/useLogRocket';
import { ErrorBoundary } from 'shared/components/ErrorBoundary/ErrorBoundary';

export const appInfo = { appVersion: parseInt(commitInfo.commitNumber) / 100, ...commitInfo };

const Application = (): JSX.Element => {
  const { getBrowser, getOS } = new UAParser();
  const browser: string = getBrowser().name || '';
  const OS: string = getOS().name || '';

  /**
   * Note: LogRocket must be initialized before ApolloClient is instantiated, otherwise core-api
   * network requests will not be included in session recordings.
   * Source: https://docs.logrocket.com/docs/troubleshooting-sessions#other-network-libraries
   */
  const { identifyUser } = useLogRocket();

  const isBrowserSupported = useMemo(() => {
    if (!OS || !browser) {
      return false;
    }

    if (browser === 'IE') {
      return false;
    }

    return true;
  }, [OS, browser]);

  // Polyfill to support `element.scrollIntoView({ behavior: 'smooth' })` on Safari
  elementScrollIntoViewPolyfill();

  useMemo(() => {
    console.log('App Info:', appInfo);
  }, []);

  return isBrowserSupported ? (
    <TokenProvider>
      <ApolloProvider uri={coreApiUrl}>
        <CareAuthProvider onIdentifyPetParent={identifyUser}>
          <KibbleThemeProvider theme={theme}>
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </KibbleThemeProvider>
        </CareAuthProvider>
      </ApolloProvider>
    </TokenProvider>
  ) : (
    <UnsupportedBrowser browser={browser} OS={OS} />
  );
};

export default Application;
