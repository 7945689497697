import React from 'react';
import ChromeLogo from 'assets/browsers/chrome_256x256.png';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Picture } from '@televet/kibble-ui/build/components/Picture';
import { Flex } from '@televet/kibble-ui/build/chakra';
interface Props {
  browser: string;
  OS: string;
}

const UnsupportedBrowser = ({ browser, OS }: Props): JSX.Element => {
  const browserName = browser === 'IE' ? 'Internet Explorer' : browser;

  return (
    <Flex
      flexDir="column"
      align="center"
      justify="center"
      flex="1"
      flexWrap="wrap"
      wordBreak="break-word"
      textAlign="center"
    >
      <Heading fontSize="30px" fontWeight="normal">
        {browserName} on {OS} is not supported
      </Heading>
      <Text fontSize="20px" m="20px">
        Our website is optimized for the latest versions of Chrome.
        <br />
        Please switch to continue your conversation with the clinic.
      </Text>

      <Flex>
        <Picture
          src={ChromeLogo}
          webpSrc=""
          imgProps={{ w: '80px', h: '80px', cursor: 'pointer', loading: 'lazy' }}
          onClick={(): void => {
            window.open('https://www.google.com/chrome');
          }}
        />
      </Flex>
    </Flex>
  );
};

export default UnsupportedBrowser;
