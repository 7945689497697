export enum SessionStorageKeys {
  OooBannerDismissed = 'oooBannerDismissed',
  EmergencyBannerDismissed = 'emergencyBannerDismissed',
  IsImpersonating = 'isImpersonating',
  QueryParams = 'queryParams',
  ConversationCarePlansBanner = 'conversationCarePlansBanner',
  HomeCarePlansBanner = 'HomeCarePlansBanner',
  CompletedPreEnrollment = 'CompletedPreEnrollment',
  PageHasBeenForceRefreshed = 'PageHasBeenForceRefreshed',
  DeviceInstanceId = 'DeviceInstanceId',
  ReAuthSuccessState = 'ReAuthSuccessState',
}
