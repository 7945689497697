import React, { ReactNode, useEffect, useState } from 'react';

export const CacheBuster = ({ children }: { children: ReactNode }): JSX.Element => {
  const [state, setState] = useState<{ loading: boolean; isLatestVersion: boolean }>({
    loading: true,
    isLatestVersion: false,
  });
  function refreshCacheAndReload(): void {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (const name of names) caches.delete(name);
      });
    }

    // delete browser cache and hard reload
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.location.reload(true);
  }
  useEffect(() => {
    fetch('/meta.json')
      .then((response) => response.json())
      .then(async (meta) => {
        const latestVersion = meta.commitHash;
        const currentVersion = localStorage.getItem('careVersion');
        const shouldForceRefresh = currentVersion && latestVersion && currentVersion !== latestVersion;
        localStorage.setItem('careVersion', latestVersion);
        if (shouldForceRefresh) {
          setState({ loading: false, isLatestVersion: false });
        } else {
          setState({ loading: false, isLatestVersion: true });
        }
      });
  }, []);
  useEffect(() => {
    if (!state.loading) {
      if (!state.isLatestVersion) {
        refreshCacheAndReload();
      }
    }
  }, [state]);
  return <>{children}</>;
};
