import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useCareAuthContext } from 'shared/providers/CareAuthProvider';
import { RouteDefinitions } from 'routes';
import { IRouteProps } from 'routes/types/RouteProps';

const ProtectedRoute = ({ component, layout: Layout, path, exact }: IRouteProps): JSX.Element => {
  const { isAuthenticated } = useCareAuthContext();

  if (!isAuthenticated) {
    return <Redirect to={RouteDefinitions.Index} />;
  }

  if (!Layout) {
    return <Route path={path} exact={exact} component={component} />;
  }

  return (
    <Layout>
      <Route path={path} exact={exact} component={component} />
    </Layout>
  );
};

export default ProtectedRoute;
