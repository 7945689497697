import { createContext } from 'react';
import { ICareAuthContext } from '../interfaces/ICareAuthContext';

export default createContext<ICareAuthContext>({
  token: undefined,
  isAuthenticated: false,
  isImpersonating: false,
  clinic: undefined,
  clinicPetParentId: '',
  clinicPetParentEmail: '',
  featureFlags: [] || undefined,
  isCareEnabled: false,
  clinicPetParent: undefined,
  isLoading: true,
  isPetParentDataLoading: true,
  refetchPetParentData: () => {
    console.log('NOT INITALIZED YET');
  },
  isEnrolledInAtLeastOnePlan: false,
  unenrolledPets: [],
  hasUnenrolledPets: false,
});
