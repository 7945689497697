import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ClaimTokenWithCodeDocument } from 'shared/types/graphql';

export const claimToken = async (code: string, client: ApolloClient<InMemoryCache>): Promise<string | null> => {
  // We have to do this here because the client itself depends on our old token
  // We have to execute this mutations like this because TokenProvider lives outside the ApolloProvider so the client doesn't exists yet
  try {
    const result = await client.mutate({
      mutation: ClaimTokenWithCodeDocument,
      variables: {
        data: {
          code,
          trusted: false,
        },
      },
    });
    const data = result?.data?.redeemOneTimeCode;
    if (data && data.success) {
      return data.token;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};
