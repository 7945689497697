import { env } from 'env';
import { useCallback } from 'react';
import { useCareAuthContext } from 'shared/providers/CareAuthProvider';

/**
 * HEADS UP: For events to be tracked and linked to the contact, every new event type
 * must be added to the whitelist on the Tracking settings page within ActiveCampaign
 */
export enum ActiveCampaignEvent {
  CreatedCareSubscription = 'Created Care subscription',
  PaidForCareSubscription = 'Paid for Care subscription',
}

declare global {
  interface Window {
    vgo?: (operation: string, ...args: string[]) => void;
  }
}

const useActiveCampaign = (): {
  trackEvent: (event: ActiveCampaignEvent, eventData?: string) => Promise<Response> | undefined;
  identifyContact: (email: string) => void;
} => {
  const { clinicPetParentEmail } = useCareAuthContext();

  /**
   * Manually identify the user so page views outside of email link clicks
   * will be tracked
   */
  const identifyContact = useCallback((email: string) => {
    if (env.REACT_APP_PRODUCTION === 'true' && typeof window.vgo === 'function') {
      window.vgo('setEmail', email);
      window.vgo('process');
    }
  }, []);

  const trackEvent = useCallback(
    (event: ActiveCampaignEvent, eventData?: string) => {
      if (!clinicPetParentEmail || env.REACT_APP_PRODUCTION !== 'true') {
        return;
      }
      try {
        return fetch('https://trackcmp.net/event', {
          method: 'Post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          mode: 'no-cors',
          body: new URLSearchParams({
            actid: env.REACT_APP_ACTIVECAMPAIGN_ACCOUNT_ID || '',
            key: env.REACT_APP_ACTIVECAMPAIGN_EVENT_KEY || '',
            event,
            eventdata: eventData || '',
            visit: JSON.stringify({ email: clinicPetParentEmail }),
          }),
        });
      } catch (error) {
        console.error('Error tracking event: ', error);
      }
    },
    [clinicPetParentEmail],
  );

  return { trackEvent, identifyContact };
};

export default useActiveCampaign;
