export enum RouteDefinitions {
  Index = '/',
  Login = '/login',
  ClinicSelectAuth = '/clinic-select/:authCode',
  ClinicSelect = '/clinic-select',
  Home = '/home',
  Requests = '/requests/connect/:clinicWidgetRequestId',
  Pets = '/pets',
  Pet = '/pets/:clinicPetId',
  ConversationsRedirect = '/conversations',
  ConversationsList = '/conversations-list',
  Conversation = '/conversations/:channelId',
  Account = '/account',
  ManagePlans = '/account/:clinicPetParentId/plans',
  LoyaltyProgram = '/account/loyalty-program',
  Plans = '/plans',
  Forms = '/forms/:formSubmissionId',
  VideoCall = '/conversation/video-call/:channelId?',
  Unsubscribe = '/unsubscribe',
  AutomationRun = '/automation-run/:automationRunId',
  Games = '/games',
  Inactive = '/inactive',
  EmailCampaignUnsubscribe = '/email-campaign-unsubscribe/:clinicPetParentId/:emailAddress/:clinicId/:emailCampaignId',
  StripeRedirect = '/stripe-redirect',
}
