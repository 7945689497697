import { useState, useEffect, Dispatch, SetStateAction } from 'react';

const usePersistedState = <T>(
  key: string,
  defaultValue: T,
  jsonReviver?: ((this: unknown, key: string, value: unknown) => unknown) | undefined,
): [T, Dispatch<SetStateAction<T>>] => {
  const currentState = localStorage.getItem(key);

  const [persistedState, setPersistedState] = useState<T>(() => {
    return currentState ? JSON.parse(currentState, jsonReviver) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(persistedState));
  }, [key, persistedState]);

  return [persistedState, setPersistedState];
};

export default usePersistedState;
