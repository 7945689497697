import HttpError from 'shared/classes/HttpError';
import { IHttp } from 'shared/interfaces/IHttp';
import { ContentTypes } from 'shared/enums/ContentTypes';
import { env } from 'env';

export const coreApiUrl = env.REACT_APP_CORE_API_URI || '';

const http = async <TResponse>({
  method,
  url,
  token,
  body,
  contentType = ContentTypes.Json,
}: IHttp): Promise<TResponse> => {
  const headers: Record<string, string> = {
    'auth-type': 'clinic_pet_parent_token',
    authorization: token ? `Bearer ${token}` : '',
  };

  if (contentType !== ContentTypes.MultiPartFormData) {
    headers['content-type'] = contentType;
  }

  const response = await fetch(url, {
    method,
    headers,
    body: body || null,
  });

  if (!response.ok) {
    throw new HttpError(response.status, response.statusText);
  }

  const data = await response.json();

  return data as TResponse;
};

export default http;
