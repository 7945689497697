import { CareEnrollmentFragment, CareProgramLaunchStatus, EnrollmentStatus } from 'shared/types/graphql';

export const isEnrollmentCancelledButStillPaying = (
  enrollment?: Pick<CareEnrollmentFragment, 'status' | 'careSubscriptionTrueUps'>,
): boolean => {
  return (
    enrollment?.status === EnrollmentStatus.Expired &&
    !!enrollment?.careSubscriptionTrueUps.some((trueUp) => !trueUp.completedAt)
  );
};

// Considers Active or Expired with unpaid true ups as active
export const isEnrollmentConsideredActive = (
  enrollment?: Pick<CareEnrollmentFragment, 'status' | 'careSubscriptionTrueUps'>,
): boolean => {
  return enrollment?.status === EnrollmentStatus.Active || isEnrollmentCancelledButStillPaying(enrollment);
};

export const isClinicAcceptingEnrollments = (launchStatus?: CareProgramLaunchStatus): boolean => {
  return launchStatus !== CareProgramLaunchStatus.Churning && launchStatus !== CareProgramLaunchStatus.Archived;
};
