import { DeviceInfo, NotificationData, PushInfo } from 'env';
import { useCallback, useEffect } from 'react';
import { useUpsertClinicPetParentDeviceMutation } from 'shared/types/graphql';
import * as Sentry from '@sentry/react';
import { SessionStorageKeys } from 'shared/enums/SessionStorageKeys';

const useAppCommunication = (clinicPetParentId: string): void => {
  const [upsertDevice] = useUpsertClinicPetParentDeviceMutation();

  const handleError = useCallback(
    (error: Error) => {
      console.log(`Error upserting device for pet parent id ${clinicPetParentId}`, error);
      Sentry.captureException(error);
    },
    [clinicPetParentId],
  );

  useEffect(() => {
    if (!!clinicPetParentId && window?.ReactNativeWebView && !window.careMobile?.saveDeviceInfo) {
      window.careMobile = {
        saveDeviceInfo: async (deviceInfo: DeviceInfo): Promise<void> => {
          try {
            sessionStorage.setItem(SessionStorageKeys.DeviceInstanceId, deviceInfo.instanceId);

            const updateInfo: Partial<DeviceInfo> = { ...deviceInfo };
            delete updateInfo.instanceId;

            const lastLogin = new Date();

            await upsertDevice({
              variables: {
                where: {
                  uniqueInstanceIdPerPetParent: {
                    clinicPetParentId,
                    instanceId: deviceInfo.instanceId,
                  },
                },
                create: {
                  clinicPetParent: { connect: { id: clinicPetParentId } },
                  lastLogin,
                  ...deviceInfo,
                },
                update: {
                  lastLogin,
                  ...updateInfo,
                },
              },
            });
          } catch (error) {
            handleError(error);
          }
        },

        savePushNotificationInfo: async (pushInfo: PushInfo): Promise<void> => {
          try {
            const lastLogin = new Date();

            await upsertDevice({
              variables: {
                where: {
                  uniqueInstanceIdPerPetParent: {
                    clinicPetParentId,
                    instanceId: pushInfo.instanceId,
                  },
                },
                create: {
                  clinicPetParent: { connect: { id: clinicPetParentId } },
                  lastLogin,
                  pushNotificationStatus: pushInfo.status,
                  instanceId: pushInfo.instanceId,
                  token: pushInfo.token,
                  os: pushInfo.os,
                  appVersion: pushInfo.appVersion,
                },
                update: {
                  lastLogin,
                  pushNotificationStatus: pushInfo.status,
                  token: pushInfo.token,
                  os: pushInfo.os,
                  appVersion: pushInfo.appVersion,
                },
              },
            });
          } catch (error) {
            handleError(error);
          }
        },
        handleNotification: async (notificationData: NotificationData): Promise<void> => {
          if (notificationData?.tinyUrl) {
            window.open(notificationData.tinyUrl);
          }
        },
        requestPushInfo: async (): Promise<void> => {
          if (window?.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'requestPushInfo' }));
          }
        },
        openSettings: (): void => {
          if (window?.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'openSettings' }));
          }
        },
        requestPushStatus: async (): Promise<string> => {
          if (window?.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'requestPushStatus' }));
            return new Promise((resolve, reject) => {
              const messageEventHandler = (event: MessageEvent): void => {
                if (!event.data) return;
                try {
                  const { type, status } = JSON.parse(event.data);
                  if (type === 'requestPushStatus') {
                    resolve(status);
                    window.removeEventListener('message', messageEventHandler); // Remove listener on resolve
                  }
                } catch (error) {
                  reject(`Error in parsing message data: ${error.message}`);
                }
              };

              window.addEventListener('message', messageEventHandler);
            });
          }
          return '';
        },
      };

      window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'requestDeviceInfo' }));
    }
  }, [clinicPetParentId, handleError, upsertDevice]);
};

export default useAppCommunication;
