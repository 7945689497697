import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createContext } from 'react';
export type RedeemCodeFn = (code: string, client: ApolloClient<InMemoryCache>) => Promise<boolean>;

export enum ClientPermissions {
  ReadCareSubscription = 'care-subscription:read',
  WriteCareSubscription = 'care-subscription:write',
  EmailCampaign = 'email-campaign',
}
type Uncontext<T> = T extends React.Context<infer U> ? U : never;
export type TokenContextData = Uncontext<typeof TokenContext>;
export const TokenContext = createContext({
  token: undefined as string | undefined,
  getLiveToken: (): string => {
    return '';
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hasScopes: (scope: ClientPermissions | ClientPermissions[]): boolean => {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  redeemCode: async (code: string, client: ApolloClient<InMemoryCache>) => {
    return false;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logout: ({ clinicId }: { clinicId: string; expired?: true }) => {
    if (clinicId) {
      return;
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setToken: (token: string) => {
    return;
  },
});
