import { useCallback, useEffect, useState } from 'react';
import LogRocket from 'logrocket';
import { env } from 'env';
import { BasicClinicPetParent } from 'shared/types/BasicClinicPetParent';
import { appInfo } from 'pages/Application';
import useCareFeatureFlag from './useCareFeatureFlag';
import { FeatureFlagKey } from 'shared/enums/FeatureFlagKeys';

const isProduction = env.REACT_APP_PRODUCTION === 'true';

const useLogRocket = (): { identifyUser: typeof identifyUser } => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { isFeatureEnabled } = useCareFeatureFlag();
  const clinicHasCareEnabled = isFeatureEnabled(FeatureFlagKey.CarePlans);

  useEffect(() => {
    if (isProduction) {
      try {
        if (isInitialized) return;
        // Merge IFrames allows us to have the content of the iframe in log rocket sessions
        LogRocket.init(env.REACT_APP_LOG_ROCKET_APP_ID || '', {
          mergeIframes: true,
          childDomains: ['*'],
          release: `${appInfo.appVersion}`,
        });
        setIsInitialized(true);
      } catch (error) {
        console.log(error);
      }
    }
  }, [isInitialized]);

  const identifyUser = useCallback(
    (clinicPetParent: BasicClinicPetParent) => {
      if (isProduction) {
        if (!isInitialized) return;
        const name = [clinicPetParent.firstName || '', clinicPetParent.lastName || ''].join(' ').trim();
        const email = clinicPetParent.email || 'No email provided';
        const userData = {
          name,
          email,
          clinicId: clinicPetParent.clinic?.id || '',
          clinicName: clinicPetParent.clinic?.name || '',
          clinicHasCareEnabled,
        };
        LogRocket.identify(clinicPetParent.id, userData);
        LogRocket.track('SessionStarted', userData);
      } else {
        console.log('identifyUser called with: ', clinicPetParent);
      }
    },
    [clinicHasCareEnabled, isInitialized],
  );

  return { identifyUser };
};

export default useLogRocket;
