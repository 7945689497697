import { DeviceOs, DeviceType, PushNotificationStatus } from 'shared/types/graphql';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    env: any;
    decode: unknown;
    ReactNativeWebView?: {
      postMessage(msg: string): void;
    };
    careMobile?: CareMobile;
  }
}

export interface CareMobile {
  savePushNotificationInfo(pushInfo: PushInfo): void;
  saveDeviceInfo(deviceInfo: DeviceInfo): void;
  handleNotification(notificationData: NotificationData): void;
  openSettings(): void;
  requestPushInfo(): Promise<void>;
  requestPushStatus(): Promise<string>;
}

export type NotificationData = {
  [key: string]: string;
};

export type PushInfo = {
  token?: string;
  status: PushNotificationStatus;
  instanceId: string;
  os: DeviceOs;
  appVersion: string;
};

export type DeviceInfo = {
  brand: string;
  type: DeviceType;
  deviceName: string;
  model: string;
  os: DeviceOs;
  osVersion: string;
  appVersion: string;
  manufacturer: string;
  instanceId: string;
  token?: string;
};

type EnvType = {
  PORT: string;
  NODE_ENV: string;
  REACT_APP_FIREBASE_KEY: string;
  REACT_APP_FIREBASE_DOMAIN: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_FIREBASE_DATABASE: string;
  REACT_APP_FIREBASE_SENDER_ID: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  REACT_APP_CORE_API_URI: string;
  REACT_APP_SUBSCRIPTIONS_URI: string;
  REACT_APP_STRIPE_API_KEY: string;
  REACT_APP_VIDEO_CALL_PLUGIN_URL: string;
  REACT_APP_TAG_MANAGER_ID: string;
  REACT_APP_GA4_ID: string;
  REACT_APP_FULL_STORY_ID: string;
  REACT_APP_LOG_ROCKET_APP_ID: string;
  REACT_APP_SENTRY_ENVIRONMENT: string;
  REACT_APP_SENTRY_URL: string;
  REACT_APP_PRODUCTION: string;
  REACT_APP_GIPHY_KEY: string;
  REACT_APP_ACTIVECAMPAIGN_ACCOUNT_ID: string;
  REACT_APP_ACTIVECAMPAIGN_EVENT_KEY: string;
  REACT_APP_PAWBLISHER_URL: string;
  REACT_APP_WIDGET_SHIM_URL: string;
};

export const env: EnvType = { ...process.env, ...window.env };
