import React from 'react';
import ReactDOM from 'react-dom';
import Application from 'pages/Application';
import 'assets/css/global.css';
import TagManager from 'react-gtm-module';
import { env } from 'env';
import { CacheBuster } from 'cacheBuster';
import { logOtto } from 'otto';
import { updateTabName } from 'updateTabName';

TagManager.initialize({
  gtmId: env.REACT_APP_TAG_MANAGER_ID || '',
});

updateTabName();
logOtto();

ReactDOM.render(
  <CacheBuster>
    <Application />
  </CacheBuster>,
  document.getElementById('root'),
);
