import { useMemo } from 'react';
import { ClinicPetByTokenFragment, EnrollmentStatus, FindPetParentByTokenQuery } from 'shared/types/graphql';
import { isEnrollmentConsideredActive } from 'shared/utils/careEnrollments';

interface IUseCheckPlanEnrollmentInput {
  petParentData: FindPetParentByTokenQuery | undefined;
  isPetParentDataLoading: boolean;
}

export interface IUseCheckPlanEnrollmentOutput {
  isEnrolledInAtLeastOnePlan: boolean;
  unenrolledPets: ClinicPetByTokenFragment[];
  hasUnenrolledPets: boolean;
}

export const useCheckPlanEnrollment = ({
  petParentData,
  isPetParentDataLoading,
}: IUseCheckPlanEnrollmentInput): IUseCheckPlanEnrollmentOutput => {
  const clinicPetParent = useMemo(() => petParentData?.findClinicPetParentByToken, [
    petParentData?.findClinicPetParentByToken,
  ]);
  const organizationPetParent = useMemo(() => clinicPetParent?.organizationPetParent, [
    clinicPetParent?.organizationPetParent,
  ]);
  const subscriptions = useMemo(() => organizationPetParent?.allCareStripeSubscriptions || [], [organizationPetParent]);

  const isEnrolled = useMemo(() => {
    if (isPetParentDataLoading) return false;
    if (!subscriptions.length) {
      return false;
    }

    const enrollments = subscriptions.flatMap((subscription) => subscription.carePlanEnrollments);

    // need to check the status of enrollments on the active care subscription if they are status active they are enrolled, if not, then they are not enrolled
    if (enrollments.length > 0) {
      return enrollments.some((enrollment) => enrollment.status === EnrollmentStatus.Active);
    }

    return false;
  }, [isPetParentDataLoading, subscriptions]);

  const unenrolledPets = useMemo(() => {
    if (isPetParentDataLoading) return [];

    const pets =
      petParentData?.findClinicPetParentByToken?.pets.filter(
        (pet) => !pet?.isDeceased && !pet?.isDeleted && pet?.isActive,
      ) || [];

    return pets.filter((pet) => {
      if (!subscriptions?.length) {
        return pet;
      }

      const activeEnrollment = pet.organizationPet?.carePlanEnrollments.filter((enrollment) =>
        isEnrollmentConsideredActive(enrollment),
      );
      return activeEnrollment?.length ? undefined : pet;
    });
  }, [isPetParentDataLoading, petParentData?.findClinicPetParentByToken?.pets, subscriptions?.length]);

  const hasUnenrolledPets = !!unenrolledPets && !!unenrolledPets.length;

  return {
    isEnrolledInAtLeastOnePlan: isEnrolled,
    unenrolledPets: unenrolledPets || [],
    hasUnenrolledPets,
  };
};
