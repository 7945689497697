import { useCallback } from 'react';

import { useCareAuthContext } from 'shared/providers/CareAuthProvider';

interface IUseCareFeatureFlagResult {
  isFeatureEnabled: (key: string) => boolean;
}

const useCareFeatureFlag = (): IUseCareFeatureFlagResult => {
  const careAuthContext = useCareAuthContext();

  const featureFlagsData = careAuthContext.featureFlags;

  /**
   * Check if feature is enabled
   */
  const isFeatureEnabled = useCallback(
    (key: string): boolean => {
      if (!featureFlagsData) return false;
      return featureFlagsData.includes(key);
    },
    [featureFlagsData],
  );

  return { isFeatureEnabled };
};

export default useCareFeatureFlag;
