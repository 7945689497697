import { TypePolicies } from '@apollo/client';
import calculatePricePerBill from './utils/calculatePricePerBill';

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      findManyChannelMessage: {
        keyArgs: ['where', 'orderBy'],
      },
      findManyLoyaltyPointDelta: {
        keyArgs: ['where'],
      },
    },
  },
  CarePlanPricing: {
    fields: {
      pricePerBill: {
        read: calculatePricePerBill,
      },
    },
  },
  CareAddonPackage: {
    fields: {
      pricePerBill: {
        read: calculatePricePerBill,
      },
    },
  },
  User: {
    fields: {
      displayName: {
        read(_, { readField }) {
          const nameDisplay = readField('nameDisplay');
          const firstName = readField('firstName');
          const lastName = readField('lastName');
          return nameDisplay || [firstName, lastName].join(' ').trim();
        },
      },
    },
  },
};

export default typePolicies;
