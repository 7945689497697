export enum GA4Events {
  INIT = 'ga4_initialized',
  HOME_CLINIC_INFO_VIEW = 'home_clinic_info_view',
  HOME_CLINIC_INFO_DISMISS = 'home_clinic_info_dismiss',
  HOME_CLINIC_INFO_CALL_CLINIC_CLICK = 'home_clinic_info_call_clinic_click',
  HOME_CLINIC_INFO_VIEW_MAP_CLICK = 'home_clinic_info_view_map_clic',
  HOME_UPCOMING_APPOINTMENT_CLICK = 'home_upcoming_appointment_click',
  HOME_CONVERSATION_CLICK = 'home_conversation_click',
  HOME_VIEW_MORE_CONVERSATIONS = 'home_view_more_conversations',
  HOME_PET_SELECT = 'home_pet_select',
  CLINIC_INFO_EMERG_INFO_CTA = 'clinic_info_emerg_info_cta',
  ACCOUNT_LOGOUT = 'account_logout',
  ACCOUNT_MANAGE_PLANS_VIEW = 'account_manage_plans_view',
  LOYALTY_BACK_CLICK = 'loyalty_back_click',
  LOYALTY_ALL_REWARDS_VIEW = 'loyalty_all_rewards_view',
  LOYALTY_HISTORY_VIEW = 'loyalty_history_view',
  LOYALTY_REWARD_REDEEM = 'loyalty_reward_redeem',
  LOYALTY_REWARD_UNREDEEM = 'loyalty_reward_unredeem',
  LOYALTY_REWARD_COLLAPSE_DETAILS = 'loyalty_reward_collapse_details',
  LOYALTY_REWARD_VIEW_DETAILS = 'loyalty_reward_view_details',
  LOYALTY_HISTORY_SHOW_MORE = 'loyalty_history_show_more',
  LOYALTY_VIEW_PROGRAM = 'loyalty_view_program',
  SPEND_VIRTUAL_CURRENCY = 'spend_virtual_currency',
  LOGIN_VALIDATION_ERROR = 'login_validation_error',
  LOGIN_LINK_SENT_SEND_LINK_AGAIN = 'login_link_sent_send_link_again',
  LOGIN_LINK_SENT_TRY_ANOTHER_METHOD = 'login_link_sent_try_another_method',
  SWITCH_CLINICS_VIEW = 'switch_clinics_view',
  SWITCH_CLINICS_SELECT = 'switch_clinics_select',
  CONVERSATION_LIST_VIEW = 'conversation_list_view',
  CONVERSATION_LIST_REQUEST_TRAY_EXPAND = 'conversation_list_request_tray_expand',
  CONVERSATION_LIST_REQUEST_TRAY_DISMISS = 'conversation_list_request_tray_dismiss',
  CONVERSATION_VIEW = 'conversation_view',
  CONVERSATION_VIEW_POST_SUBMIT = 'conversation_view_post_submit',
  CONVERSATION_UPLOAD_ATTACHMENT = 'conversation_upload_attachment',
  CONVERSATION_ADD_GIF = 'conversation_add_gif',
  CONVERSATION_ADD_EMOJI_SEND = 'conversation_add_emoji_send',
  CONVERSATION_ARCHIVED_CLICK = 'conversation_archived_click',
  CONVERSATION_START_VIDEO_CALL = 'conversation_start_video_call',
  CONVERSATION_END_VIDEO_CALL = 'conversation_end_video_call',
  CONVERSATION_BACK_NAVIGATION = 'conversation_back_navigation',
  CONVERSATION_OOO_INFO_DISMISS = 'conversation_OOO_info_dismiss',
  CONVERSATION_OOO_INFO_CTA_CLICK = 'conversation_OOO_info_cta_click',
  CONVERSATION_SETTINGS_BACK_NAVIGATION = 'conversation_settings_back_navigation',
  CONVERSATION_SETTINGS_OPEN = 'conversation_settings_open',
  CONVERSATION_SETTINGS_DISMISS = 'conversation_settings_dismiss',
  CONVERSATION_GO_TO_ACTIVE = 'conversation_go_to_active',
  MEMBERSHIP_HOME_BANNER_CTA = 'membership_home_banner_cta',
  MEMBERSHIP_HOME_BANNER_VIEW = 'membership_home_banner_view',
  MEMBERSHIP_CONVERSATION_BANNER_CTA = 'membership_conversation_banner_cta',
  MEMBERSHIP_CONVERSATION_BANNER_VIEW = 'membership_conversation_banner_view',
  MEMBERSHIP_REQUEST_BANNER_CTA = 'membership_request_banner_cta',
  MEMBERSHIP_FUNNEL_EXIT_CLICK = 'membership_funnel_exit_click',
  MEMBERSHIP_FUNNEL_FORWARD_TO = 'membership_funnel_forward_to',
  MEMBERSHIP_FUNNEL_CONFIRMATION_CHAT = 'membership_funnel_confirmation_chat',
  MEMBERSHIP_FUNNEL_VIEW = 'membership_funnel_view',
  MEMBERSHIP_FUNNEL_PLAN_CARD_CLICK = 'membership_funnel_plan_card_click',
  MEMBERSHIP_FUNNEL_START_CTA = 'membership_funnel_start_cta',
  MEMBERSHIP_FUNNEL_CLINIC_LOGO = 'membership_funnel_clinic_logo',
  MEMBERSHIP_FUNNEL_CAROUSEL_VIEW = 'membership_funnel_carousel_view',
  MEMBERSHIP_FUNNEL_FAQ_EXPAND = 'membership_funnel_faq_expand',
  MEMBERSHIP_FUNNEL_FAQ_COLLAPSE = 'membership_funnel_faq_collapse',
  MEMBERSHIP_FUNNEL_SAVINGS_BANNER = 'membership_funnel_savings_banner',
  MEMBERSHIP_FUNNEL_CHECKOUT_CONDITIONS = 'membership_funnel_checkout_conditions',
  MEMBERSHIP_FUNNEL_CHECKOUT_VIEW = 'membership_funnel_checkout_view',
  MEMBERSHIP_FUNNEL_CHECKOUT_ERROR = 'membership_funnel_checkout_error',
  MEMBERSHIP_FUNNEL_CHECKOUT_SUBMIT = 'membership_funnel_checkout_submit',
  MEMBERSHIP_FUNNEL_CONFIRMATION_VIEW = 'membership_funnel_confirmation_view',
  MEMBERSHIP_FUNNEL_TRAY_DISMISS = 'membership_funnel_tray_dismiss',
  MEMBERSHIP_FUNNEL_SELECT_PET = 'membership_funnel_select_pet',
  MEMBERSHIP_FUNNEL_SELECT_PLAN = 'membership_funnel_select_plan',
  MEMBERSHIP_FUNNEL_SELECT_PRICING = 'membership_funnel_select_pricing',
  MEMBERSHIP_FUNNEL_REVIEW_CONFIRM = 'membership_funnel_review_confirm',
  MEMBERSHIP_FC_STRIPE_INPUT_READY = 'membership_fc_stripe_input_ready',
  MEMBERSHIP_FC_STRIPE_INPUT_START = 'membership_fc_stripe_input_start',
  MEMBERSHIP_FC_STRIPE_INPUT_CLEAR = 'membership_fc_stripe_input_clear',
  MEMBERSHIP_FC_STRIPE_INPUT_COMPLETE = 'membership_fc_stripe_input_complete',
  MEMBERSHIP_PRE_ENROLLMENT_INFO = 'membership_pre_enrollment_info',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  PURCHASE = 'purchase',
  ADD_PAYMENT_INFO = 'add_payment_info',
  BEGIN_CHECKOUT = 'begin_checkout',
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_CART = 'view_cart',
  PET_PROFILE_PET_ID_CTA_CLICK = 'pet_profile_pet_id_cta_click',
  PET_PROFILE_PET_ID_VIEW = 'pet_profile_pet_id_view',
  PET_PROFILE_PET_ID_DISMISS = 'pet_profile_pet_id_dismiss',
  PET_PROFILE_VIEW = 'pet_profile_view',
  PET_PROFILE_PHOTO_EDIT = 'pet_profile_photo_edit',
  PET_PROFILE_CARE_CTA_CLICK = 'pet_profile_care_cta_click',
  PETS_PET_SELECT = 'pets_pet_select',
  NAVIGATION_HOME = 'navigation_home',
  NAVIGATION_PETS = 'navigation_pets',
  NAVIGATION_CONVERSATIONS = 'navigation_conversations',
  NAVIGATION_ACCOUNT = 'navigation_account',
  NOTIFICATIONS_PUSH_ALERT = 'notifications_push_alert',
  NOTIFICATIONS_PUSH_ALERT_DISMISS = 'notifications_push_alert_dismiss',
  NOTIFICATIONS_PUSH_ALERT_ENABLE = 'notifications_push_alert_enable',
  NOTIFICATIONS_PUSH_MODAL = 'notifications_push_modal',
  NOTIFICATIONS_PUSH_MODAL_DISMISS = 'notifications_push_modal_dismiss',
  NOTIFICATIONS_PUSH_MODAL_ENABLE = 'notifications_push_modal_enable',
  // Dynamic events
  CONVERSATION_SETTINGS = 'conversation_settings',
  EMERG_INFO_CTA = 'emerg_info_cta',
  EMERG_INFO_DISMISS = 'emerg_info_dismiss',
  GET_HELP = 'get_help',
  NOT_YOUR_CLINIC = 'not_your_clinic',
  REQUEST_TRAY_DISMISS = 'request_tray_dismiss',
  REQUEST_TRAY_EXPAND = 'request_tray_expand',
  // The following events get appended to a request type prefix (Ex: rx_refill_start)
  // See WidgetRequestTypeEventPrefix.ts for the prefixes
  SUBMIT = 'submit',
  DONE = 'done',
}
