import React from 'react';
import * as Sentry from '@sentry/react';
import { SessionStorageKeys } from 'shared/enums/SessionStorageKeys';
import { Button } from '@televet/kibble-ui/build/components/Button';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Icon } from '@televet/kibble-ui/build/components/Icon';
import { Logo } from '@televet/kibble-ui/build/components/Logo';
import { Text } from '@televet/kibble-ui/build/components/Text';
import { Box, Flex } from '@televet/kibble-ui/build/chakra';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorName: string;
}

enum KnownError {
  ChunkLoadError = 'ChunkLoadError',
}

const reloadPage = (): void => {
  const params = new URLSearchParams(window.location.search);
  const reload = params.get('r');
  const number = !!reload ? Number(reload) + 1 : 1;
  params.set('r', `${number}`);
  window.location.href = window.location.origin + window.location.pathname + `?${params.toString()}`;
};

const retryPageReload = (pageHasAlreadyBeenForceRefreshed: boolean): void => {
  if (!pageHasAlreadyBeenForceRefreshed) {
    window.sessionStorage.setItem(SessionStorageKeys.PageHasBeenForceRefreshed, 'true');
    reloadPage();
  } else {
    window.sessionStorage.setItem(SessionStorageKeys.PageHasBeenForceRefreshed, 'false');
  }
};

const errorTitle: Record<string, string> = {
  [KnownError.ChunkLoadError]: 'Sorry about that!',
};

const errorDescription: Record<string, string> = {
  [KnownError.ChunkLoadError]: 'There is a new version of our app. Please refresh the page to update.',
};

const errorCTA: Record<string, JSX.Element> = {
  [KnownError.ChunkLoadError]: (
    <Button onClick={reloadPage} iconName="arrowCircle">
      Refresh
    </Button>
  ),
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: Readonly<ErrorBoundaryProps>) {
    super(props);
    this.state = { hasError: false, errorName: '' };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState | void {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.sessionStorage.getItem(SessionStorageKeys.PageHasBeenForceRefreshed) || 'false',
    ) as boolean;

    if (error?.name === KnownError.ChunkLoadError && !pageHasAlreadyBeenForceRefreshed) {
      retryPageReload(pageHasAlreadyBeenForceRefreshed);
    } else {
      return { hasError: true, errorName: error?.name };
    }
  }

  componentDidCatch(error: Error): void {
    this.setState({ hasError: true, errorName: error?.name });
    Sentry.captureException(error);
  }

  render(): React.ReactNode {
    const { hasError, errorName } = this.state;

    if (!hasError) {
      return this.props.children;
    }

    return (
      <Box bgColor="background.subtle" width={{ base: '100%', lg: '50%' }} margin="0 auto" h="100%">
        <Flex alignItems="center" justifyContent="center" flexDirection="column" h="100%" position="relative">
          <Icon name="warningSign" color="text.interactive" size="xl" />
          <Heading my={2} size="md">
            {errorTitle[errorName] || 'Oops!'}
          </Heading>
          <Text as="p" mb={5} textAlign="center" maxW="275px">
            {errorDescription[errorName] ||
              "You've encountered an issue. Our support team has been notified and is working to fix it. Please try again later."}
          </Text>
          {errorCTA[errorName]}
          <Box w="120px" position="absolute" bottom={5}>
            <Logo />
          </Box>
        </Flex>
      </Box>
    );
  }
}
