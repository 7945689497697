export const updateTabName = (): void => {
  let tabName = document.title;
  const url = window.location.href;
  const environmentRegex = /.*\.(.*)\.dev/;
  const environment = url?.match(environmentRegex)?.[1] || '';
  if (url.includes('localhost')) {
    tabName += ' [local]';
  }
  if (environment) {
    tabName += ` [${environment}]`;
  }
  document.title = tabName;
};
