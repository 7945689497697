import { FieldReadFunction } from '@apollo/client';
import { CarePlanCycleType } from 'shared/types/graphql';

const calculatePricePerBill: FieldReadFunction = (_, { readField }): number => {
  const pricePerRenewal = readField<number>('pricePerRenewal');
  const renewalCycle = readField<CarePlanCycleType>('renewalCycle');
  const billingCycle = readField<CarePlanCycleType>('billingCycle');
  if (!pricePerRenewal || !renewalCycle || !billingCycle) {
    return 0;
  }
  if (renewalCycle === CarePlanCycleType.Annually && billingCycle === CarePlanCycleType.Monthly) {
    return Math.floor(pricePerRenewal / 12);
  }
  return pricePerRenewal;
};

export default calculatePricePerBill;
