// Deprecated - This is here to help with Kibble UI upgrade.
import { DeviceSizes } from './DeviceSizes';

export const ResponsiveDeviceMaxWidth = {
  mobileS: '(max-width: ' + DeviceSizes.mobileS + ')',
  mobileM: '(max-width: ' + DeviceSizes.mobileM + ')',
  mobileL: '(max-width: ' + DeviceSizes.mobileL + ')',
  tablet: '(max-width: ' + DeviceSizes.tablet + ')',
  laptop: '(max-width: ' + DeviceSizes.laptop + ')',
  laptopL: '(max-width: ' + DeviceSizes.laptopL + ')',
  desktop: '(max-width: ' + DeviceSizes.desktop + ')',
  desktopL: '(max-width: ' + DeviceSizes.desktop + ')',
};
